import React from "react";

const ComingSoon = () => {
  return (
    <h5>
      <b>Coming Soon</b>
    </h5>
  );
};

export default ComingSoon;
