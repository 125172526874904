import React, {Component} from "react";
import Slide from "react-reveal/Slide";
import "./Sns.scss";
import {youtube, instagram, twitter, facebook, linkedin,tiktok} from "./snsUrls";
import {
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,  
} from "react-icons/fa";

export default class SNS extends Component {
  render() {
    return (
      <Slide left cascade>
        <div className="floating-sns">
          <a href={youtube} target="_blank" rel="noopener noreferrer">
            <div className="floating-sns__circle">
              <FaYoutube className="floating-sns__icon" />
            </div>
          </a>
          <a href={instagram} target="_blank" rel="noopener noreferrer">
            <div className="floating-sns__circle">
              <FaInstagram className="floating-sns__icon" />
            </div>
          </a>
          <a href={twitter} target="_blank" rel="noopener noreferrer">
            <div className="floating-sns__circle">
              <FaTwitter className="floating-sns__icon" />
            </div>
          </a>
          <a href={facebook} target="_blank" rel="noopener noreferrer">
            <div className="floating-sns__circle">
              <FaFacebookF className="floating-sns__icon" />
            </div>
          </a>
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <div className="floating-sns__circle">
              <FaLinkedinIn className="floating-sns__icon" />
            </div>
          </a>
          <a href={tiktok} target="_blank" rel="noopener noreferrer">
            <div className="floating-sns__circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" fill="currentColor" className="bi bi-tiktok floating-sns__icon" viewBox="0 0 16 16">
  <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
</svg>
            </div>
          </a>
        </div>
      </Slide>
    );
  }
}
