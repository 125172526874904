import React, { useState, useEffect } from "react";
import style from "./contactform.module.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {withRouter} from "react-router-dom";
import CoverImage from "../../../assets/cover3d.png";
import logoimage from "../../../assets/logo_without-tagline.png";

const ThankYouPage = ({history}) => {

  useEffect(() => {
    // Function to add the script
    const addConversionScript = () => {
      window.gtag('event', 'conversion', {
        send_to: 'AW-810421545/LWvKCJnymakZEKmauIID',
        value: 1.0,
        currency: 'AUD'
      });
    };

    // Check if gtag function exists
    if (typeof window.gtag !== 'undefined') {
      addConversionScript();
    } else {
      // If gtag is not yet available, you might want to load the gtag.js script first
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-810421545';
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){ window.dataLayer.push(arguments); }
        window.gtag = gtag;
        window.gtag('js', new Date());
        window.gtag('config', 'AW-810421545');
        addConversionScript();
      };
      document.head.appendChild(script);
    }
  }, []);

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    history.push("/");
  };


  return (
    <>
       <Modal show={show} className={style.modal_outer} onHide={handleClose} centered>
       
       <Modal.Body className="px-4 pt-5 modal_body">
        <div>
        <img src={logoimage} className={style.logo_image} />
         <p className={style.thank_msj}>
           you made it… your decision to get this report just gave you an unfair
           advantage. Thanks for trusting us with your info. Your free report is on
           the way, we will get that over to you shortly Have a great day!
         </p>
        </div>
         {/* <div className={style.footer_text}>
           <p>
             I’m Matt.
             <br />
             I compiled the data,
             <br />
             Wrote the report,
             <br />
             Convinced my boss to give to you for free,
             <br />
             And I will be prioritising your business needs.
           </p>
           <p>
             I personally, will meet with you,
             <br />
             Create a dream candidate avatar with you,
             <br />
             Understand your business,
             <br />
             Offer you my ‘Matt Madness’ discount structure,
             <br />
             Ensure ‘Va-Va Zoom’ speedy placements,
           </p>
           <p>
             And ultimately treat you like my family’s well-being depended on it,
             Because it does.
           </p>
           <p>
             As a South African immigrant and brand new to the country, I am only
             too eager to bring you success, Which in turn creates stability for my
             wife and daughters.
           </p>
           <p>
             Enjoy the report.
             <br />
             Speak with you soon.
           </p>
         </div> */}
       </Modal.Body>
       <Modal.Footer>
         <Button variant="primary" onClick={handleClose}>
           Back
         </Button>
       </Modal.Footer>
     </Modal>
    </>
  );
};


export default withRouter(ThankYouPage);
