import React, {useState, useEffect} from "react";
import style from "./contactform.module.scss";
import {ContactusForm} from "../../../utils/api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {withRouter} from "react-router-dom";
import CoverImage from "../../../assets/cover3d.png";
import logoimage from "../../../assets/logo_without-tagline.png";

function Contactform({history}) {
  // exit box 
    const [showExitIntent, setShowExitIntent] = useState(false);
  
    useEffect(() => {
      const handleExitIntent = (e) => {
        if (e.clientY < 0) {
    setShowExitIntent(true);
  }
      };
  
      document.addEventListener('mouseout', handleExitIntent);
  
      return () => {
        document.removeEventListener('mouseout', handleExitIntent);
      };
    }, []);
  
    const handleClose = () => {
      setShowExitIntent(false);
    };
    
    console.log(showExitIntent);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  
  const handleChange = (e) => {
    const {name, value} = e.target;
    let error = "";
    switch (name) {
      case "name":
        error = value.trim() && value.length > 2 ? "" : "Name is required";
        break;
      case "email":
        error = value.trim()
          ? /^\S+@\S+\.\S+$/.test(value)
            ? ""
            : "Email is invalid"
          : "Email is required";
        break;
      case "mobile":
        if (!/^\d*$/.test(value)) {
          return;
        }
        error =
          value.trim().length > 7 && value.trim().length < 16
            ? ""
            : "Please enter valid mobile number";
        break;
      default:
        break;
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required";
    } else if (
      !(formData.mobile.trim().length > 7 && formData.mobile.trim().length < 16)
    ) {
      errors.mobile = "Please enter valid mobile number";
    }
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      console.log("Form submitted:", formData);
      setLoading(true);
      ContactusForm({
        firstName: formData.name,
        email: formData.email,
        phoneNo: formData.mobile,
        content: "",
      })
        .then((res) => {
          setLoading(true);
          console.log("response--", res);
          if (res?.status == 200) {
            history.push("/thank-you")
          }
        })
        .catch((error) => {
          setLoading(true);
          console.log(error);
        });
    }
  };

  return (

    // exit box 
    <>
    
    <div className={`exit-intent-box ${showExitIntent ? 'show' : ''}`}>
      <div className="exit-intent-content">
        <button className="close-btn" style={{
  position: 'absolute',
  right: '40px',
  fontSize: '30px',
  top: '10px',
  border: 'none',
  background: 'transparent',
  color: '#0d56a4'
}} onClick={handleClose}>&#10006;</button>



       <p>
  <span className="blue-text block bold-text large-text">Hey! I'm Matt.</span>
  <span className="mt-5 block">I compiled the data,</span>
  <span className="block">Wrote the report,</span>
  <span className="bold-text block margin-bottom-10">Convinced my boss to give to you for free,</span>
  <span className="block">And will personally prioritise your <strong>labour hire</strong> needs.</span><br/>
  <span className="font-weight-bold lead">Together we will:</span><br/>
  <ul className="exit-box-ul">
    <li>Create your dream candidate avatar,</li>
    <li>Implement the <strong>Matt Madness</strong> pricing deal,</li>
    <li>Give you VVIP speed placement preference,</li>
    <li>Include our Melbourne surprise package.</li>
  </ul>

This offer is valid for 7 business days…<br/>

As I need to process 72 leads per week<br/>

And move on.<br/>

 
<br/>
(We can still serve your business,

but not with this deal.)
<br/><br/>
 
<div className="exit-box-m">
As an immigrant & brand new to the country,<br/>

I am eager to bring you success,<br/>

Like my daughters depended on it - which they do.
</div>
<br/><br/>

Enjoy the report<br/>

<span>Speak with you soon</span>
</p>
      </div>
    </div>
    
    <div className={`${showExitIntent ? 'bg-custom-dark' : ''}`}></div>


    <div className={`${style.advice_menu}`}>
      <div className={style.main_heading}>
        <h2 className={style.heading}>
          Uncover the 11 'Hiring Trends' that could 2X your bottom line in 12 months,
          hedge 100% injury cover claims for 6 months & reduce staff churn
          'HEADACHES' by 19%.
        </h2>
      </div>
      <div className={style.heading_sec}>
        <p className={style.sub_heading}>
          BYE-BYE Tyre Kickers, Time Wasters, Expensive on-boarders, and DISCOVER how
          Top Companies are changing their landscape & crushing their Operational
          Productivity demands.
        </p>
      </div>
      {/* <div className={style.cover_images}>
        <img src={CoverImage} className={style.image_cover} />
        </div>  
        <div className={style.form_sub}>
          <form onSubmit={handleSubmit}>
            <div className={style.form_group}>
              <label className={style.label}>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={style.input_field}
                autoComplete="off"
              />
              {errors.name && (
                <span className={style.error_message}>{errors.name}</span>
              )}
            </div>
            <div className={style.form_group}>
              <label className={style.label}>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={style.input_field}
                autoComplete="off"
              />
              {errors.email && (
                <span className={style.error_message}>{errors.email}</span>
              )}
            </div>
            <div className={style.form_group}>
              <label className={style.label}>Mobile Number:</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className={style.input_field}
                autoComplete="off"
              />
              {errors.mobile && (
                <span className={style.error_message}>{errors.mobile}</span>
              )}
            </div>
            <button type="submit" className={style.submit_button}>
              Submit
            </button>
          </form>
        </div>    */}
      <div className={style.advice_container}>
        <div className={style.cover_image}>
          <img src={CoverImage} className={style.image_cover} />
        </div>
        <div className={style.form_sub}>
          <form onSubmit={handleSubmit} className={style.form_inputs}>
            <div className={style.form_group}>
              <h5 className={style.form_title}>
                Please fill in your details to have this ‘BANGER’ 2024 Report sent
                directly to your email inbox:
              </h5>
              <label className={style.label}>Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={style.input_field}
                autoComplete="off"
              />
              {errors.name && (
                <span className={style.error_message}>{errors.name}</span>
              )}
            </div>
            <div className={style.form_group}>
              <label className={style.label}>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={style.input_field}
                autoComplete="off"
              />
              {errors.email && (
                <span className={style.error_message}>{errors.email}</span>
              )}
            </div>
            <div className={style.form_group}>
              <label className={style.label}>Mobile Number:</label>
              <input
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className={style.input_field}
                autoComplete="off"
              />
              {errors.mobile && (
                <span className={style.error_message}>{errors.mobile}</span>
              )}
            </div>
            <button type="submit" className={style.submit_button}>
              Submit
            </button>
          </form>
        </div>
      </div>

      <div className={style.heading_sec}>
        <p className={style.sub_heading2}>
          The Labour Market has changed. Now we have no choice but to INNOVATE if we
          are to thrive for many years to come
        </p>
      </div>
      {loading ? (
        <div className="loader-overly">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
     
    </div>
    </>
  );
}

export default withRouter(Contactform);
