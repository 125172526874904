import React, {useState, useEffect} from "react";
import style from "./blog.module.scss";
import {Link, NavLink} from "react-router-dom";
import {AiOutlineRight} from "react-icons/ai";
import {getBlogList} from "../../../utils/api";
import Markdown from 'react-markdown'

// AiOutlineRight
const Singleblog = () => {

  const location = window.location.pathname;
  const loct = location.slice(10);
  const newblog = loct;
  // console.log("blog url-----------------",newblog)
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    getAllBlogList();
  }, []);

  const getAllBlogList = () => {
    getBlogList()
      .then((res) => {
        let data = res.data.result;
        setBlogData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={style.main}>
        <div className={style.container}>
          <div className={style.page_route}>
            <NavLink
              exact
              to="/"
              activeClassName="active"
              className={style.link_col}>
              Home
            </NavLink>
            <AiOutlineRight className={style.page_icon} />
            <NavLink
              className={style.link_col}
              to="/advice-insights"
              activeClassName="active"
              style={{whiteSpace: "nowrap"}}>
              Insights
            </NavLink>
            <AiOutlineRight className={style.page_icon} />
            <NavLink
              className={style.link_col}
              to="/blog"
              activeClassName="active"
              style={{whiteSpace: "nowrap"}}>
              Blog
            </NavLink>
          </div>

          <div className={style.blog_start}>
            <div className={style.blog_items}>
              {blogData?.length > 0
                ? blogData?.map((item, i) => {
                    if (item._id == newblog) {
                      let data_summary = item.summary;
                      let summary_detail = data_summary;
                      let add_on = [];
                      for (let value of summary_detail)
                        add_on.push({
                          heading: value.heading,
                          value: value.value,
                        });

                        console.log("summary---------------------", add_on)
                      return (
                        <div className={style.blox_box}>
                          <h5 className={style.blog_heading}>{item.tittle}</h5>
                          <div className={style.blog_images}>
                            <img
                              src={item.image}
                              alt="blog"
                              width="70%"
                            />
                          </div>
                          <div className={style.full_desc}>
                            <p>{item.description}</p>
                            {
                              add_on?.length > 0 ?
                              add_on?.map((sum)=>{
                                return(
                                  <>
                                  <h5 className={style.sum_heading}>{sum.heading}</h5>
                                  {/* <p>{sum.value?.replaceAll("\n", " \n \t ")}</p> */}
                                 <Markdown>{sum.value?.split("\n")?.join("  \n  \t ")}</Markdown>
                                  </>
                                )
                              }) :""
                            }
                          </div>
                        </div>
                      );
                    }
                  })
                : ""}
            </div>
            <div className={style.popular}>
              <div className={style.article}>
                <h5 className={style.article_head}>Popular articles</h5>
                <div className={style.jobseeker}>
                  {blogData?.length > 0
                    ? blogData?.slice(0, 10).map((item,i) => {
                        return (
                          <>
                            <Link to={`/insights/${item._id}`}>
                             {item.tittle}
                            </Link>
                          </>
                        );
                      })
                    : ""}
                </div>
                {/* <div className={style.jobseeker}>
                  <h6>Employer</h6>
                  {allblog?.length > 0
                    ? allblog?.slice(10).map((item) => {
                        return (
                          <>
                            <p>{item.heading}</p>
                          </>
                        );
                      })
                    : ""}
                </div> */}
                <h6 className={style.view_blog}>View all blogs</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Singleblog;
