import React, { useState } from "react";
// import PropTypes from "prop-types";
// import "./style.scss";
// import "../../../components/Hero/Hero.scss";
// import StyledTitle from "../../../components/StyledTitle/StyledTitle";
// import ProfilePanel from "./components/ProfilePanel";
// import App from "../../../App";
import style from "./adviceInsights.module.scss";
import { Modal } from "react-bootstrap";
// import wcbPdf from "../../../../assets/WhiteCollarBlue-Company-Newsletter.pdf";
// import wcfpdf from "../../../../src/assets/WhiteCollarBlue-Company-Newsletter.pdf"
import Wcfpdf from "../../../assets/WhiteCollarBlue-Company-Newsletter.pdf"
import business from "../../../assets/business-meeting.jpg";
// import { Link } from "react-router-dom";
import {AiOutlineClose} from "react-icons/ai"
import { Link } from "react-router-dom";
import { subscription } from "../../../utils/api";

const AdviceInsights = () => {

  const [email , setEmail] = useState("")
  const [modal, setModal] = React.useState(false);
  const [isValid, setIsValid] = useState({email:""});

  const handleEmial = (e) =>{
    // console.log(e.target.value)
    const inputValue = e.target.value;
    setEmail(inputValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(inputValue));
  }

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    let valid = true;
    const newErrors = {...isValid };
    const emailRegex = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (!email) {
      valid = false;
      newErrors.email = "Please enter your email address";
    } 
    else if (!emailRegex.test(email)) {
      valid = false;
      newErrors.email = "Please enter a valid email address";
    }
    setIsValid(newErrors);
    if (valid) {
      console.log(email);
      subscription({
        email : email
      }).then((res)=>{
        // console.log("subscription email--------------", res)
        if(res.data.code == 200){
          setModal(true)
          setEmail("")
        }
      }).catch((error)=>{
        console.log(error)
      })
      
    }
  }


  return (
    <>
      <div className={style.advice_menu}>
        <div className={style.advice_container}>
          <div className={style.section1}>
            <div className={style.advice_head}>
              <h1>Insights</h1>
            </div>
          </div>
          
        </div>
        <div className={style.main_image_sec}>
          <div className={style.main_image}>
          <img src={business} alt="Advice & Insights" />

          </div>
          </div>
        <div className={style.section2}>
          <div className={style.sec2_head}>
            <h1>The Vibe by WhiteCollarBlue</h1>
          </div>
          <div className={style.pera}>
            <div className={style.peragraph}>
              <p>
                Welcome to The Vibe by WhiteCollarBlue, a page dedicated to market
                insights, advice and exciting recruitment news. &nbsp;
              </p>
              <p>
                For our clients - The Vibe is the best place to find out the latest
                information on recruitment practices, the shifts in hiring trends and
                how WhiteCollarBlue can help your business. We also add some fun and
                flair to the day to day office life we all experience. &nbsp;
              </p>
              <p>
                For our candidates - The Vibe provides some exciting insight into
                traits of a great employee, new and exciting opportunities advertised
                on our socials and gratuity and recognition for our high performing
                temps out with host clients. &nbsp;
              </p>
            </div>
          </div>
        </div>
        <div className={style.section3}>
          <div className={style.sec3_images}>
            <div className={style.newslatter}>
              <img src="https://static.wixstatic.com/media/b45f45_932ba6361d1a490a88d5af2bfa5b1392~mv2.png/v1/fill/w_286,h_335,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Modern%20Minimalist%20Graffiti%20Dream%20Brand%20Logo.png" alt="Advice & Insights" />
              <div className={style.news_btn}>
              {/* <a href={Wcfpdf} download="WhiteCollarBlue Company Newsletter.pdf"><button>WCB Newsletter</button></a> */}
                <Link to="/newsletter"><button>WCB Newsletter</button></Link>
              </div>
            </div>

            <div className={style.blog_sec}>
              <img src="https://static.wixstatic.com/media/b45f45_5581628cb1c14566a131b08a3853abaf~mv2.jpg/v1/crop/x_84,y_0,w_427,h_500/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/1619857497990.jpg" alt="Advice & Insights" />
              <div className={style.blog_btn}>
              <a href="/blog"> <button>WCB Blog</button></a>
              </div>
            </div>
          </div>
        </div>

        <section className={style.section4}>
          <div className={style.subscribe}>
            <div className={style.subscribe_heading}>
              <a href="mailto:info@whitecollarblue.com.au">Subscribe to The Vibe</a>
            </div>
            <div>
              <label htmlFor="email">Enter your email here *</label>
              <input type="email" name="email" autocomplete="off" value={email} onChange={(e)=>handleEmial(e)} className={`${style.email_input}`} />
              <span className={style.error}>{isValid.email}</span>
              <button type="submit" onClick={(e)=>handleSubmitEmail(e)} className={style.signup_btn}>Sign Up</button>
            </div>
          </div>
        </section>
      </div>
      <Modal
          show={modal}
          onHide={() => setModal(false)}
          centered
          className="p-0 modal-outer"
        >
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                {/* <i className="bi bi-check-circle"></i> */}
                <AiOutlineClose className="close" onClick={() => setModal(false)}/>               
              </div>
              <div className="modal-body text-center">
                <h4>Thank you!</h4>
                <p>We will reach you out soon.</p>
              </div>
            </div>
          </div>
        </Modal>
    </>
  );
};

export default AdviceInsights;
 