import React, {useState} from "react";
import style from "./blog.module.scss";
import {AiOutlineCloseCircle} from "react-icons/ai";
import {addBlogList} from "../../../utils/api";
import {withRouter} from "react-router-dom";

const Addblog = ({history}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState([{heading: "", value: ""}]);
  const [loading, setLoading] = useState(false);

  const handleAddInput = () => {
    setInputs([...inputs, {heading: "", value: ""}]);
  };

  const handleChange = (event, index) => {
    let {name, value} = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputData, setInputData] = useState({
    file: {},
    tittle: "",
    description: "",
  });
  const [formErrors, setFormErrors] = useState({
    file: "",
    tittle: "",
    description: "",
  });

  const handleFileChange = (e) => {
    const file_img = e.target.files[0];

    setSelectedFile(file_img);
    setInputData({
      ...inputData,
      file: file_img,
    });
  };

  const handleInputChange = (e) => {
    console.log({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const updateProduct = async (index) => {
    let valid = true;
    const newErrors = {...formErrors};
    if (!selectedFile) {
      valid = false;
      newErrors.file = "Please Select Image";
    }

    if (!inputData.tittle) {
      valid = false;
      newErrors.tittle = "Please Enter Title";
    }

    if (!inputData.description) {
      valid = false;
      newErrors.description = "Please Enter Description";
    }

    setFormErrors(newErrors);

    if (valid) {
      setFormErrors("");

      const formData = new FormData();
      formData.append("blog_image", inputData.file);
      formData.append("tittle", inputData.tittle);
      formData.append("description", inputData.description);
      formData.append("summary", JSON.stringify(inputs));
      setLoading(true);
      addBlogList(formData)
        .then((res) => {
          if (res.data.code == 200) {
            setLoading(false);
            setMessage(res.data.message);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              history.push({
                pathname: "/super-admin/blog",
              });
            }, [500]);
          } else {
            setMessage(res.data.message);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              setError(false);
            }, [5000]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);  
        });
    }
  };

  return (
    <>
      {success ? (
        <div className="toaster">
          <div className={"alert"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div className="toaster">
          <div className={"alert-error"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={style.profile}>
        <div className={style.profile_head}>
          <h2 className={style.heading_pro}>Add New Blog</h2>
        </div>
        <div className="mt-5">
          <label className={style.label}>Image</label>
          <input
            className="form-control mb-3"
            placeholder="Enter User Name"
            type="file"
            name="file"
            onChange={(e) => handleFileChange(e)}
            accept=".jpg , .png , .jpeg"
          />
          <div className="text-danger">{formErrors.file}</div>
          <label className={style.label}>Title</label>
          <input
            className="form-control mb-3"
            placeholder="Enter Title"
            type="text"
            name="tittle"
            value={inputData.tittle}
            onChange={handleInputChange}
          />
          <div className="text-danger">{formErrors.tittle}</div>

          <label className={style.label}>Description</label>
          <input
            className="form-control mb-3"
            placeholder="Enter Description"
            type="text"
            name="description"
            value={inputData.description}
            onChange={handleInputChange}
          />
          <div className="text-danger">{formErrors.description}</div>

          <label className={style.label}>Summary</label>
          <div className={style.summary_border}>
            {inputs.map((item, index) => (
              <>
                <div className="row mt-3" key={index}>
                  <div className="col-md-11">
                    <label className="ms-3">Heading</label>
                    <input
                      name="heading"
                      type="text"
                      placeholder="Heading"
                      className="form-control rid-input"
                      value={item.heading}
                      onChange={(event) => handleChange(event, index)}
                    />
                    <label className="ms-3 mt-2">Summary</label>
                    <textarea
                      className="form-control mt-1 rid-input"
                      name="value"
                      type="text"
                      rows={5}
                      placeholder="Value"
                      value={item.value}
                      onChange={(event) => handleChange(event, index)}
                    />
                  </div>

                  <div className="col-md-1">
                    {inputs.length > 1 && (
                      <div className="col-md-1 mt-9 cross-icon">
                        <AiOutlineCloseCircle
                          className={style.cross_btn}
                          onClick={() => handleDeleteInput(index)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.add_more}>
                  {index === inputs.length - 1 && (
                    <button
                      className={style.add_morebtn}
                      onClick={() => handleAddInput()}>
                      + Add More
                    </button>
                  )}
                </div>
              </>
            ))}
          </div>
        </div>
        <div>
          <div className={style.update_div}>
            <button onClick={updateProduct} className={style.add_btn}>
              Add
            </button>
          </div>
        </div>
        {loading ? (
          <div className="loader-overly">
            <div className="loader"></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default withRouter(Addblog);
