import Axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

Axios.defaults.baseURL = API_URL

const token = localStorage.getItem("token")
// console.log("tokennnnnnnnn", token)
export const userLogin = async (data) => {
    // console.log(data);
    return Axios
      .post(`/admin/admin_login`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log('res++++++++++++++++++++++++++++++',res)

        // console.log(res);
        if(res.data.code===200){
          localStorage.setItem("token", res?.data?.result?.jwtToken);
          // console.log('res++++++++++++++++++++++++++++++',res)
        }
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const getBlogList = async () => {
    // console.log("dataaaaaaaaaaaaaaaa");
    return Axios
      .get(`/admin/get_blog`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };


  export const addBlogList = async (data) => {
    // console.log(data);
    return Axios
      .post(`/admin/add_blog`, data, {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const getBlogDetail = async (id) => {
    // console.log("dataaaaaaaaaaaaaaaa",id);
    return Axios
      .get(`/admin/get_blog_detail/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const EditBlogDetail = async (id, data) => {
    // console.log("dataaaaaaaaaaaaaaaa",id);
    return Axios
      .put(`/admin/edit_blog/${id}`,data, {
        headers: {
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const deleteBlog = async (id) => {
    // console.log("dataaaaaaaaaaaaaaaa",id);
    return Axios
      .delete(`/admin/delete_blog/${id}`, {
        headers: {
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const getNewsList = async () => {
    // console.log("dataaaaaaaaaaaaaaaa");
    return Axios
      .get(`/admin/get_news`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };


  export const addNewsList = async (data) => {
    // console.log(data);
    return Axios
      .post(`/admin/add_news`, data, {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const getNewsDetail = async (id) => {
    // console.log("dataaaaaaaaaaaaaaaa",id);
    return Axios
      .get(`/admin/get_news_detail/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const EditNewsDetail = async (id, data) => {
    // console.log("dataaaaaaaaaaaaaaaa",id);
    return Axios
      .put(`/admin/edit_news/${id}`,data, {
        headers: {
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const deleteNews = async (id) => {
    // console.log("dataaaaaaaaaaaaaaaa",id);
    return Axios
      .delete(`/admin/delete_news/${id}`, {
        headers: {
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  // get_admin_detail
  export const getAdminDetail = async () => {
    // console.log("dataaaaaaaaaaaaaaaa");
    return Axios
      .get(`/admin/get_admin_detail`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };


  export const editAdminDetail = async (data) => {
    // console.log("dataaaaaaaaaaaaaaaa");
    return Axios
      .put(`/admin/edit_admin_detail`,data, {
        headers: {
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const subscription = async (data) => {
    // console.log(data);
    return Axios
      .post(`/admin/subscription_email`, data, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const getBlogAdmin = async () => {
    // console.log("dataaaaaaaaaaaaaaaa");
    return Axios
      .get(`/admin/get_blog_by_admin`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const getNewsAdmin = async () => {
    // console.log("dataaaaaaaaaaaaaaaa");
    return Axios
      .get(`/admin/get_news_by_admin`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const getSubscriberList = async () => {
    // console.log("dataaaaaaaaaaaaaaaa");
    return Axios
      .get(`/admin/list_subscribe_emails?page=1&limit=10&status=true`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  // change_email_status
  export const updateEmailStatus = async (data) => {
    // console.log("dataaaaaaaaaaaaaaaa");
    return Axios
      .patch(`/admin/change_email_status`,data, {
        headers: {
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  export const addNewEmail = async (data) => {
    // console.log(data);
    return Axios
      .post(`/admin/subscription_email`, data, {
        headers: {
          "Content-Type": "application/json",
          "x-access-token" : token
        },
      })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((error) => {
        // console.log("error", error);
      });
  }

  export const ContactusForm = async (data) => {
    // console.log(data);
    return Axios
      .post(`/admin/contact_us`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log(error)
      });
  };