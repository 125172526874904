import React, {useState, useEffect} from "react";
import style from "./newsletter.module.scss";
import cubelogo from "../../../assets/cube-logo.png";
import {Link} from "react-router-dom";
import {AiOutlineCloseCircle} from "react-icons/ai";
import {withRouter} from "react-router-dom";
import {EditNewsDetail, getNewsDetail} from "../../../utils/api";

const Singlenews = ({history}) => {
  const API_URL = process.env.REACT_APP_NEWS_IMAGE;
  const location = window.location.pathname;
  const path = location.split("/");
  const paramId = path[3];
  console.log("location-------------", paramId);

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState([{heading: "", value: ""}]);

  const handleAddInput = () => {
    setInputs([...inputs, {heading: "", value: ""}]);
  };

  const handleChange = (event, index) => {
    let {name, value} = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;
    setInputs(onChangeValue);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [inputData, setInputData] = useState({
    id: "",
    file: {},
    title: "",
    description: "",
  });
  const [formErrors, setFormErrors] = useState({
    file: "",
    title: "",
    description: "",
  });

  const handleFileChange = (e) => {
    const file_img = e.target.files[0];

    setSelectedFile(file_img);
    setInputData({
      ...inputData,
      file: file_img,
    });
  };

  const handleInputChange = (e) => {
    console.log({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const updateProduct = async () => {
    let valid = true;
    const newErrors = {...formErrors};
    if (!inputData.file) {
      valid = false;
      newErrors.file = "Please Select Image";
    }

    if (!inputData.title) {
      valid = false;
      newErrors.title = "Please enter title";
    }
    if (!inputData.description) {
      valid = false;
      newErrors.description = "Please enter Description";
    }

    setFormErrors(newErrors);

    if (valid) {
      setFormErrors("");

      const id = inputData.id;
      const formData = new FormData();
      formData.append("image", inputData.file);
      formData.append("tittle", inputData.title);
      formData.append("description", inputData.description);
      formData.append("summary", JSON.stringify(inputs));

      EditNewsDetail(id, formData)
        .then((res) => {
          if (res.data.code == 200) {
            setLoading(false);
            setMessage(res.data.message);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              history.push({
                pathname: "/super-admin/newsletter",
              });
            }, [500]);
          } else {
            setLoading(true);
            setMessage(res.data.message);
            setError(true);

            setTimeout(() => {
              setError(false);
              setLoading(false);
            }, [2000]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  console.log(paramId);

  const getblogid = () => {
    setLoading(true);
    getNewsDetail(paramId)
      .then((res) => {
        if (res.data.code == 200) {
          let value = res.data.result;
          let data_summary = res.data.result.summary;
          let add_on = [];
          for (let value of data_summary)
            add_on.push({
              heading: value.heading,
              value: value.value,
            });
          setInputs(add_on);
          setInputData({
            id: value._id,
            title: value.tittle,
            file: value.image,
            description: value.description,
          });
          setLoading(false);
        } else {
          setMessage(res.data.message);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            setError(false);
          }, [2000]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getblogid();
  }, []);

  return (
    <>
      {success ? (
        <div className="toaster">
          <div className={"alert"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div className="toaster">
          <div className={"alert-error"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={style.profile}>
        <div className={style.profile_head}>
          <h2 className={style.heading_pro}>Edit Newsletter</h2>
        </div>
        <div className={style.blog_start}>
          <div className="mt-5">
            <label className={style.label}>Image</label>
            <div>
              {inputData.file && (
                <img
                  src={`${API_URL}${inputData.file}`}
                  alt="Preview"
                  style={{width: "50px", marginBottom: "10px"}}
                />
              )}
            </div>
            <input
              className="form-control mb-3"
              placeholder="Enter User Name"
              type="file"
              name="file"
              onChange={(e) => handleFileChange(e)}
              accept=".jpg , .png , .jpeg"
            />
            <span className="text-danger">{formErrors.file}</span>
            <label className={style.label}>Title</label>
            <input
              className="form-control mb-3"
              placeholder="Enter Title"
              type="text"
              name="title"
              value={inputData.title}
              onChange={handleInputChange}
            />
            <span className="text-danger">{formErrors.title}</span>
            <label className={style.label}>Description</label>
            <input
              className="form-control mb-3"
              placeholder="Enter Description"
              type="text"
              name="description"
              value={inputData.description}
              onChange={handleInputChange}
            />
            <span className="text-danger">{formErrors.description}</span>
            <label className={style.label}>Summary</label>
            <div className={style.summary_border}>
              {inputs?.map((item, index) => (
                <>
                  <div className="row mt-3" key={index}>
                    <div className="col-md-11">
                      <label className="ms-3">Heading</label>
                      <input
                        name="heading"
                        type="text"
                        placeholder="Heading"
                        className="form-control rid-input"
                        value={item.heading}
                        onChange={(event) => handleChange(event, index)}
                      />
                      <label className="ms-3 mt-2">Summary</label>
                      <textarea
                        className="form-control mt-1 rid-input"
                        name="value"
                        type="text"
                        rows={5}
                        placeholder="Value"
                        value={item.value}
                        onChange={(event) => handleChange(event, index)}
                      />
                    </div>

                    <div className="col-md-1">
                      {inputs.length > 1 && (
                        <div className="col-md-1 mt-9 cross-icon">
                          <AiOutlineCloseCircle
                            className={style.cross_btn}
                            onClick={() => handleDeleteInput(index)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={style.add_more}>
                    {index === inputs.length - 1 && (
                      <button
                        className={style.add_morebtn}
                        onClick={() => handleAddInput()}>
                        + Add More
                      </button>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className={style.update_div}>
            <button onClick={updateProduct} className={style.add_btn}>
              Add
            </button>
          </div>
        </div>
        {loading ? (
          <div className="loader-overly">
            <div className="loader"></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default withRouter(Singlenews);
