import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import "react-phone-input-2/lib/style.css";
// import Button from 'react-bootstrap/Button';
// styles
// import {jobData} from "./jobData";
import Axios from "axios";
import "./jobFulldetails.scss";
import $, {error} from "jquery";
// componen
// import {Link} from "react-router-dom";

const JobFullDetails = ({location, history}) => {
  const searchParams = new URLSearchParams(location.search);
  const param1 = searchParams.get("ja-job");
  const params = param1.replace("#", "");
  console.log("params------------------------", params);

  const [data, setData] = useState([]);
  const [jobdetail, setJobdetail] = useState(true);
  const [formopen, setFormopen] = useState([]);
  const [applyform, setApplyform] = useState([]);
  const [remindModal, setRemindModal] = useState(false);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState({email: ""});
  const [minjquery , setMinjquery] = useState([])

  // https://apply.jobadder.com/au5/1800/717800/hprrr5dgftpe3cbezcjndkmtfm

  useEffect(() => {
    getJobData()
    $('#btnSubmit').prop('disabled', false);

    $(document.body).on("click", ".back-link", function () {
      history.push({
        pathname: "/jobseeker/findjob",
      });
    });
    $(document.body).on("click", ".ja-button", function () {
      setJobdetail(false);
      setApplyform(true);
      // getformdata();
    });
  }, []);

  // useEffect(() => {
  //   getform();
  //   getJobData();
  //   applyJobadder();
  //   getimageAccount()
  //   getimageLoading()
  //   getminjquery();
  //   getvalidation();
  //   getdefaultcommon();
  //   getdefaultform();
  //   getselectpng();
  //   getcountryCode();
  //   getnewRelic();
  //   getnetData();
  //   getflagicons();
  //   // var url = window.location.href;
  //   // url = String(url).replace(/#/, "");
  // },[]);

  // const getform = () => {
  //   const response = Axios.get(
  //     `https://apps.jobadder.com/widgets/V1/Jobs/RenderApplicationFormIframe?callback=jQuery191018000209051479388_1695027740128&Url=https%3A%2F%2Fapply.jobadder.com%2Fau5%2F1800%2F719922%2Fhprrr5dgftpe3cbezcjndkmtfm&_=1695027740130`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };
  // // https://apply.jobadder.com/styles/default?v=QEqZH_2OpRqjWB57vRHjrNW0mfJI4WjjUSZn4S8NlPA1

  // const applyJobadder = () => {
  //   const response = Axios.get(
  //     `https://apply.jobadder.com/styles/default?v=QEqZH_2OpRqjWB57vRHjrNW0mfJI4WjjUSZn4S8NlPA1`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // const getimageAccount = () => {
  //   const response = Axios.get(
  //     `https://apply.jobadder.com/images/accountlogo/au5/1800`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // // https://apply.jobadder.com/Assets/Images/loading.gif
  // const getimageLoading = () => {
  //   const response = Axios.get(
  //     `https://apply.jobadder.com/Assets/Images/loading.gif`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };
  // // https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js

  // const getminjquery = () => {
  //   const response = Axios.get(
  //     `https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js`
  //   )
  //     .then((res) => {
  //       console.log("res===================", res);
  //       setMinjquery(res.data)
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // const getvalidation = () => {
  //   const response = Axios.get(
  //     `https://ajax.aspnetcdn.com/ajax/jquery.validate/1.19.1/jquery.validate.min.js`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // const getdefaultcommon = () => {
  //   const response = Axios.get(
  //     `https://apply.jobadder.com/scripts/default/common?v=jS4cO_1Gbu8yUM9GW6xs3bqqk2s-QJ0DlxKARufEj0g1`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // const getdefaultform = () => {
  //   const response = Axios.get(
  //     `https://apply.jobadder.com/scripts/default/form?v=Wfh_twkBOCppXKYuiuuXiG-izo-7se86uO6nF1jFqJA1`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // const getselectpng = () => {
  //   const response = Axios.get(
  //     `https://apply.jobadder.com/assets/images/select2.png`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // const getcountryCode = () => {
  //   const response = Axios.get(
  //     `https://apply.jobadder.com/country-code`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };
  // // https://js-agent.newrelic.com/nr-rum.b96ea0dc-1.240.0.min.js
  // const getnewRelic = () => {
  //   const response = Axios.get(
  //     `https://js-agent.newrelic.com/nr-rum.b96ea0dc-1.240.0.min.js`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // const getnetData = () => {
  //   const response = Axios.get(
  //     `https://bam.nr-data.net/1/NRJS-0cab42a0e829a0b1ec1?a=858571611&v=1.240.0&to=Z1EEMBQHVxZRBkxRVl4bKzIlSX0AVgRNVE1zWwgQFAlVCVUXF3FXVFEe&rst=2081&ck=0&s=d42c54a077f3db67&ref=https://apply.jobadder.com/au5/1800/719922/hprrr5dgftpe3cbezcjndkmtfm&ap=1119&be=1735&fe=313&dc=221&perf=%7B%22timing%22:%7B%22of%22:1695028058171,%22n%22:0,%22f%22:5,%22dn%22:5,%22dne%22:5,%22c%22:5,%22s%22:5,%22ce%22:5,%22rq%22:24,%22rp%22:1735,%22rpe%22:1876,%22di%22:1952,%22ds%22:1952,%22de%22:1956,%22dc%22:2046,%22l%22:2046,%22le%22:2048%7D,%22navigation%22:%7B%7D%7D&fp=1867&fcp=1867`
  //   )
  //     .then((res) => {
  //       // console.log("res===================", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };


  const getJobData = async () => {
    const response = Axios.get(
      `https://apps.jobadder.com/widgets/V1/Jobs/RenderJobDetails?callback=jQuery191024292478656742422_1694857241191&key=AU5_hprrr5dgftpe3cbezcjndkmtfm&showDatePosted=true&datePostedFormat=%7B0%7D&dateFormat=d%2FM%2Fyyyy&showClassifications=true&classificationsToExclude=&showSalary=false&salaryFormat=%7B0%7D&includeSalaryText=false&showJobReference=false&jobReferenceFormat=%7B0%7D&showBulletPoints=true&applyButtonText=Apply+Now&backLinkText=Back+to+search+results&useExternalApplicationForm=true&showExternalApplicationFormInNewWindow=false&jobID=${params}&_=1694857241192`
    )
      .then((res) => {
        // console.log("response--------------", res.data)
        console.log(res.data);
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  };

  let text = String(data);
  let arr = text.split(" ");
  arr.shift();
  let item = arr.join(" ");
  let newitem = unescape(JSON.parse(`"${item.slice(0, -3)}"`));
  

  // const handleInputChange = (e) => {
  //   const inputValue = e.target.value;
  //   setEmail(inputValue);
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   setIsValid(emailRegex.test(inputValue));
  // };

  // const handleSubmitEmail = (e) => {
  //   e.preventDefault();
  //   let valid = true;
  //   const newErrors = {...isValid};
  //   const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
  //   if (!email) {
  //     valid = false;
  //     newErrors.email = "Please enter your email address";
  //   } else if (!emailRegex.test(email)) {
  //     valid = false;
  //     newErrors.email = "Please enter a valid email address";
  //   }
  //   setIsValid(newErrors);
  //   if (valid) {
  //     console.log(email);
  //     setRemindModal(false);
  //   }
  // };

  // const getflagicons = () => {
  //   const response = Axios.get(`https://apply.jobadder.com/assets/images/flags.jpg`)
  //     .then((res) => {
  //       console.log("flagggggggggggggggggggggggggggg", res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // // const handleApply = (item) => {
  // //   console.log("id---------------", item.heading);
  // //   setApplyform(item);
  // //   setJobdetail(false);
  // // };

  // const handleClose = () => setRemindModal(false);
  // // const handleShow = () => setRemindModal(true);

  // // const handleEmail =(e)=>{
  // //   console.log(e.target.value)
  // //   setEmail(e)
  // // }

  const handleBackdata =()=>{
    if(window.confirm("Are you sure you want to cancel?")){
      history.push({
      pathname: "/jobseeker/findjob"
    });
    }  
   
  }

  // const getformdata = () => {
  //   // https://apps.jobadder.com/widgets/V1/Jobs/RenderApplicationFormIframe?callback=jQuery1910842765445545119_1694861457757&Url=https%3A%2F%2Fapply.jobadder.com%2Fau5%2F1800%2F717800%2Fhprrr5dgftpe3cbezcjndkmtfm&_=1694861457759
  //   const response = Axios.get(
  //     `https://apply.jobadder.com/au5/1800/${params}/hprrr5dgftpe3cbezcjndkmtfm`,
  //     {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     },
  //     {
  //       mode: "no-cors",
  //     }
  //   )
  //     .then((res) => {
  //       console.log(res.data, "-------------------------");
  //       setFormopen(res.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   return response;
  // };

  // const handleSubmit = (e) => {
  //   console.log("formData=======", formData);
  //   e.preventDefault();
  //   let valid = true;
  //   const newErrors = {...formErrors};

  //   if (!formData.fname) {
  //     valid = false;
  //     newErrors.fname = "Please enter your first name";
  //   } else if (formData.fname.length < 3) {
  //     valid = false;
  //     newErrors.fname = "Please enter your valid first name";
  //   }

  //   if (!formData.lname) {
  //     valid = false;
  //     newErrors.lname = "Please enter your last name";
  //   } else if (formData.lname.length < 3) {
  //     valid = false;
  //     newErrors.lname = "Please enter your valid last name";
  //   }

  //   const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
  //   if (!formData.email) {
  //     valid = false;
  //     newErrors.email = "Please enter your email address";
  //   } else if (!emailRegex.test(formData.email)) {
  //     valid = false;
  //     newErrors.email = "Please enter a valid email address";
  //   }

  //   if (!formData.mobile) {
  //     valid = false;
  //     newErrors.mobile = "This field is required.";
  //   }
  //   // if (!formData.phone) {
  //   //   valid = false;
  //   //   newErrors.phone = 'This field is required.';
  //   // }

  //   setFormErrors(newErrors);
  //   if (valid) {
  //     console.log(formData);
  //     const response = Axios.post(
  //       `https://apply.jobadder.com/au5/1800/${params}/hprrr5dgftpe3cbezcjndkmtfm`,{

  //       }
  //     )
  //       .then((res) => {
  //         console.log(res.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     return response;
  //   }
  // };

  // const handleChange = (e) => {
  //   console.log({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });

  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });

  //   setFormErrors({
  //     ...formErrors,
  //     [e.target.name]: "",
  //   });
  // };

  // const handlePhonedata = (e) => {
  //   setFormData({...formData, phone: e});
  // };
  // const handleMobileData = (e) => {
  //   setFormData({...formData, mobile: e});
  // };

  return (
    <>
      {jobdetail == true ? <div dangerouslySetInnerHTML={{__html: newitem}} /> : ""}
      {applyform == true ? (
        // <div className={style.form_wrapp}>
        //   <form onSubmit={handleSubmit}>
        //     <div className={style.form_inner}>
        //       <div className={style.form_heading}>
        //         <h3>{applyform.heading}</h3>
        //         <p>Please complete the form below to apply for this position</p>
        //       </div>

        //       <div className={style.apply_form}>
        //         <div className={style.apply_resume}>
        //           <span className={`${style.btn_file} full-width`}>
        //             Upload your resume
        //             <input
        //               id="CandidateResume"
        //               className={style.form_input}
        //               name="CandidateResume"
        //               type="file"
        //             />
        //           </span>
        //           <div className={style.selected_file_name}></div>
        //         </div>
        //         <div className={style.apply_or}>
        //           <span>or</span>
        //         </div>
        //         <div className={style.apply_later}>
        //           <button
        //             type="button"
        //             id="btnApplyLater"
        //             // name="btnApplyLater"
        //             onClick={handleShow}
        //             className={`btn full-width ${style.apply_later_btn}`}
        //             title="Apply Later">
        //             Apply Later
        //           </button>
        //         </div>
        //       </div>

        //       <div className={`row ${style.form_row}`}>
        //         <div className={`col-md-6 ${style.first_field}`}>
        //           <label>First Name</label>
        //           <input
        //             type="text"
        //             name="fname"
        //             value={formData.fname}
        //             onChange={handleChange}
        //             className={`form-control ${style.first_name}`}
        //           />
        //           <span className={style.error}>{formErrors.fname}</span>
        //         </div>
        //         <div className={`col-md-6 ${style.last_field}`}>
        //           <label>Last Name</label>
        //           <input
        //             type="text"
        //             name="lname"
        //             value={formData.lname}
        //             onChange={handleChange}
        //             className={`form-control ${style.last_name}`}
        //           />
        //           <span className={style.error}>{formErrors.lname}</span>
        //         </div>
        //       </div>
        //       <div className={`row ${style.email_row}`}>
        //         <div className={`col-md-12 ${style.email_field}`}>
        //           <label>Email</label>
        //           <input
        //             type="email"
        //             name="email"
        //             value={formData.email}
        //             onChange={handleChange}
        //             className={`form-control ${style.email_name}`}
        //           />
        //           <span className={style.error}>{formErrors.email}</span>
        //         </div>
        //       </div>
        //       <div className={`row ${style.phone_row}`}>
        //         <div className={`col-md-12 ${style.phone_field}`}>
        //           <label>Phone</label>
        //           <PhoneInput
        //             enableSearch="true"
        //             className={`${style.phone_input}`}
        //             country={"in"}
        //             value={formData.phone}
        //             onChange={(e) => handlePhonedata(e)}
        //           />
        //           <span className={style.error}>{formErrors.phone}</span>
        //         </div>
        //       </div>
        //       <div className={`row ${style.phone_row}`}>
        //         <div className={`col-md-12 ${style.phone_field}`}>
        //           <label>Mobile</label>
        //           <PhoneInput
        //             enableSearch="true"
        //             className={`${style.phone_input}`}
        //             country={"in"}
        //             value={formData.mobile}
        //             onChange={(e) => handleMobileData(e)}
        //           />
        //           <span className={style.error}>{formErrors.mobile}</span>
        //         </div>
        //         <div className={`row ${style.check_row}`}>
        //           <div className={`col-md-12 ${style.check_field}`}>
        //             <label>Cover note</label>
        //             <div className={style.radio_field}>
        //               <label htmlFor="coverNoteType0" className={style.label1}>
        //                 <input
        //                   className={style.radio_input1}
        //                   type="radio"
        //                   value="Attach"
        //                   name="coverNoteType"
        //                   id="coverNoteType0"
        //                   checked="true"
        //                   onClick={() => setChooseFile(true)}
        //                 />
        //                 Attach
        //               </label>
        //               <label for="coverNoteType1" className={style.label1}>
        //                 <input
        //                   className={style.radio_input1}
        //                   type="radio"
        //                   value="Write now"
        //                   name="coverNoteType"
        //                   id="coverNoteType1"
        //                   onClick={() => setChooseFile(false)}
        //                 />
        //                 Write now
        //               </label>
        //             </div>
        //           </div>
        //         </div>
        //         {chooseFile == true ? (
        //           <div className={`row ${style.chhose_row}`}>
        //             <div className={`col-md-12`}>
        //               <input
        //                 type="file"
        //                 name="file"
        //                 value={formData.file}
        //                 onChange={handleChange}
        //               />
        //             </div>
        //           </div>
        //         ) : (
        //           <div className={`row ${style.textarea_row}`}>
        //             <div className={`col-md-12 form-control`}>
        //               <textarea
        //                 typeof="text"
        //                 name="file"
        //                 value={formData.file}
        //                 onChange={handleChange}
        //                 className={style.teaxtarea_field}
        //                 type="text"></textarea>
        //             </div>
        //           </div>
        //         )}
        //         <div className={`row ${style.apply_btn_row}`}>
        //           <div className={`col-md-12`}>
        //             <button type="submit" className={style.apply_now_btn}>
        //               Apply Now
        //             </button>
        //           </div>
        //         </div>
        //         <div className={style.job_link}>
        //           <a title="JobAdder.com" href="//jobadder.com" target="_blank">
        //             JobAdder.com
        //           </a>
        //         </div>
        //       </div>
        //     </div>
        //   </form>
        //   <div className={style.apply}>
        //     <Link to="/jobseeker/findjob" className={style.back_link}>
        //       Back to search results
        //     </Link>
        //   </div>
        // </div>
        <>
        {/* <div dangerouslySetInnerHTML={{__html: formopen}} />
        <script>{minjquery}</script> */}
        <iframe src={`https://apply.jobadder.com/au5/1800/${params}/hprrr5dgftpe3cbezcjndkmtfm`} scrolling="no" style={{width: "100%", border: "none", overflow: "hidden", height:" 776px"}} allow="microphone; camera" id="iFrameResizer0"></iframe>
        <div className="apply"> <button className="back-links" onClick={()=>handleBackdata()} title="Back to search results">Back to search results</button> </div>
        </>
      ) : (
        ""
      )}

      {/* <Modal show={remindModal} onHide={handleClose}>
        <div closeButton>
          <button className={`${style.close_btn}`} onClick={handleClose}>
            X
          </button>
        </div>
        <div>
          <div className={style.modal_head}>
            <h4>Want to apply later?</h4>
          </div>
          <div className={style.modal_body}>
            <p>Enter your email address below to receive a reminder to apply.</p>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleInputChange}
              className={`form-control`}
              placeholder="Enter your email address"
            />
            <span className={style.error}>{isValid.email}</span>
          </div>
        </div>
        <div className={style.modal_footer}>
          <button
            className={style.set_reminder}
            onClick={(e) => handleSubmitEmail(e)}>
            Set Reminder
          </button>
          <button className={style.cancel_btn} onClick={handleClose}>
            Cancel
          </button>
        </div>
      </Modal> */}
     
    </>
  );
};
export default JobFullDetails;
