import React, {useState, useEffect} from "react";
import styles from "../newsletter.module.scss";
import cubelogo from "../../../assets/cube-logo.png";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {
  EditNewsDetail,
  deleteNews,
  getNewsAdmin,
} from "../../../utils/api";
import {AiFillDelete, AiFillEdit, AiOutlineCloseCircle} from "react-icons/ai";

const CustomComponent = () => {

  
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setBlog] = useState([]);
  const [show, setShow] = useState(false);
  // const API_URL = process.env.REACT_APP_NEWS_IMAGE;

  useEffect(() => {
    getAllNewsList();
  }, []);

  const getAllNewsList = () => {
    setLoading(true);
    getNewsAdmin()
      .then((res) => {
        if (res.data.code == 200) {
          setBlog(res.data.result);
          setLoading(false);
        } else {
          setMessage(res.data.message);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            setError(false);
          }, [2000]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleClose = () => setShow(false);

  const handleDelete = (id) => {
    setShow(id);
  };

  const handleDeletzone = (id) => {
    console.log(id);
    setLoading(true);
    deleteNews(id)
      .then((res) => {
        console.log("delete ", res);
        if (res.data.code == 200) {
          setShow(false);
          setLoading(false);
          getAllNewsList();
          setMessage(res.data.message);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, [2000]);
        } else {
          setMessage(res.data.message);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            setError(false);
          }, [2000]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleStatus = (item, status) => {
    const id = item._id;
    setLoading(true);
    const formData = new FormData();
    formData.append("blog_image", item.image);
    formData.append("tittle", item.tittle);
    formData.append("description", item.description);
    formData.append("summary", JSON.stringify(item.summary));
    formData.append("status", status);
    EditNewsDetail(id, formData)
      .then((res) => {
        console.log("response-----------------", res);
        if (res.data.code == 200) {
          getAllNewsList();
          setLoading(false);
          setMessage(res.data.message);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, [2000]);
        } else {
          setMessage(res.data.message);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            setError(false);
          }, [2000]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
    {success ? (
        <div className="toaster">
          <div className={"alert"}>
            <div>
              
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div className="toaster">
          <div className={"alert-error"}>
            <div>
              
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}

    <div className={styles.outer}>
      <div className={styles.heading}>
        <h3 className={styles.news_head}>News Letter</h3>
        <div className={styles.add_btn}>
          <Link to="/super-admin/addnewsletter">
            <button type="button" className={styles.add_item}>
              Add
            </button>
          </Link>
        </div>
        {loading ? (
          <div className="loader-overly">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            
            <div>
              <table className="table mt-3">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Image</th>
                    <th scope="col">Title</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {data?.length > 0
                  ? data?.map((item, i) => {
                      return (
                        <tbody>
                          <tr>
                            <td scope="row">{i + 1}</td>
                            <td>
                              <img
                                className={styles.cube_logo}
                                src={item.image}
                                width={50}
                                alt="newsletter"
                              />
                            </td>
                            <th>
                              {item.tittle.length > 50
                                ? `${item.tittle.slice(0, 50)}...`
                                : item.tittle}
                            </th>
                            <td>
                              {item.status == true ? (
                                <i
                                  className={`bi bi-toggle-on ${styles.active_btn}`}
                                  onClick={() => handleStatus(item, false)}></i>
                              ) : (
                                <i
                                  className={`bi bi-toggle-off ${styles.inactive_btn}`}
                                  onClick={() => handleStatus(item, true)}></i>
                              )}
                            </td>
                            <td>
                              <Link to={`/super-admin/newsletterId/${item._id}`}>
                                <AiFillEdit className={styles.edit_btn} />
                                {/* <button type="button" className={styles.edit_btn}>
                              Edit
                            </button> */}
                              </Link>
                              <AiFillDelete
                                onClick={() => handleDelete(item._id)}
                                className={styles.delete_btn}
                              />

                              {/* <button type="button" onClick={()=>handleDelete(item._id)} className={styles.delete_btn}>
                            Delete
                          </button> */}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })
                  : ""}
              </table>
            </div>
          </>
        )}
      </div>
      {/* <div className={styles.modal}> */}
      <Modal
        className={styles.modal_dialoge}
        show={show}
        onHide={handleClose}
        centered>
        <Modal.Body className="text-center py-5">
          <h4 className="sure-delete">Are you sure to delete</h4>
          <div className="mt-4">
            <button
              type="button"
              className={styles.del_btn}
              onClick={() => handleDeletzone(show)}>
              Ok
            </button>
            <button
              type="button"
              className={styles.cancel_btn}
              onClick={() => handleClose()}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    </>
    
  );
};

export default CustomComponent;
