import React, {useEffect, useState} from "react";
import styles from "./newsletter.module.scss";
import {editAdminDetail, getAdminDetail} from "../../utils/api";
import {AiOutlineCloseCircle} from "react-icons/ai";

const AdminProfile = () => {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    getAdminDetail()
      .then((res) => {
        if (res.data.code == 200) {
          let val = res.data.result;
          setInputData({
            username: val.f_name + " " + val.l_name,
            email: val.email,
            phoneNo: val.phoneNo,
          });
          setMessage(res.data.message);
          setLoading(false);
        } else {
          setMessage(res.data.message);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            setError(false);
          }, [2000]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const [inputData, setInputData] = useState({
    username: "",
    email: "",
    phoneNo: "",
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    email: "",
    phoneNo: "",
  });

  const handleInputChange = (e) => {
    console.log({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const updateProduct = async () => {
    let valid = true;
    const newErrors = {...formErrors};
    if (!inputData.username) {
      valid = false;
      newErrors.username = "Please Enter Name";
    }

    const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (!inputData.email) {
      valid = false;
      newErrors.email = "Please enter your email address";
    } else if (!emailRegex.test(inputData.email)) {
      valid = false;
      newErrors.email = "Please enter a valid email address";
    }

    if (!inputData.phoneNo) {
      valid = false;
      newErrors.phoneNo = "Please Enter Phone number";
    }

    setFormErrors(newErrors);

    if (valid) {
      setFormErrors("");
      setLoading(true);
      let username = inputData.username.split(" ");
      editAdminDetail({
        f_name: username[0],
        l_name: username[1],
        email: inputData.email,
        phoneNo: inputData.phoneNo,
      })
        .then((res) => {
          if (res.data.code == 200) {
            setLoading(false);
            setMessage(res.data.message);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
            }, [2000]);
          } else {
            setMessage(res.data.message);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              setError(false);
            }, [2000]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <div className={styles.profile}>
      {success ? (
        <div className="toaster">
          <div className={"alert"}>
            <div>
              
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div className="toaster">
          <div className={"alert-error"}>
            <div>
              
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.profile_head}>
        <h2 className={styles.heading_pro}>Admin Profile</h2>
      </div>
      <div className="mt-5">
        <input
          className="form-control my-3"
          placeholder="Enter User Name"
          type="text"
          name="username"
          value={inputData.username}
          onChange={handleInputChange}
        />
        <span className="text-danger">{formErrors.username}</span>
        <input
          className="form-control my-3"
          placeholder="Enter Email"
          type="text"
          name="email"
          value={inputData.email}
          onChange={handleInputChange}
        />
        <span className="text-danger">{formErrors.email}</span>
        <input
          className="form-control my-3"
          placeholder="Enter Phone Number"
          type="text"
          name="phoneNo"
          value={inputData.phoneNo}
          onChange={handleInputChange}
        />
        <span className="text-danger">{formErrors.phoneNo}</span>
      </div>
      <div>
        <div className={styles.update_div}>
          <button onClick={updateProduct} className={styles.update_btn}>
            Update
          </button>
        </div>
      </div>
      {loading ? (
        <div className="loader-overly">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminProfile;
