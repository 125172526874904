import React, {useEffect, useState} from "react";
import {CardBody} from "reactstrap";
import styles from "./login.module.scss";
import {withRouter} from "react-router-dom";
import logopng from "../../../assets/logo.png";
import {userLogin} from "../../../utils/api";
import {AiFillEye, AiFillEyeInvisible, AiOutlineCloseCircle} from "react-icons/ai";

const Loginform = ({history}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token != null) {
      history.push({
        pathname: "/super-admin/blog",
      });
    } else {
      history.push({
        pathname: "/super-admin/login",
      });
    }
  }, []);

  const handleInputChange = (e) => {
    // console.log({
    //   ...inputData,
    //   [e.target.name]: e.target.value,
    // });

    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const updateProduct = async () => {
    let valid = true;
    const newErrors = {...formErrors};

    const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (!inputData.email) {
      valid = false;
      newErrors.email = "Please enter your email address";
    } else if (!emailRegex.test(inputData.email)) {
      valid = false;
      newErrors.email = "Please enter a valid email address";
    }
    if (!inputData.password) {
      valid = false;
      newErrors.password = "Please Enter Password";
    } else if (inputData.password.length < 6) {
      valid = false;
      newErrors.password = "Password must be greater than 6";
    }

    setFormErrors(newErrors);

    if (valid) {
      setFormErrors("");
      setLoading(true);
      userLogin({
        email: inputData.email,
        password: inputData.password,
      })
        .then((response) => {
          if (response.data.code === 200) {
            localStorage.setItem("token", response?.data.result.jwtToken);
            // console.log("valueeeeeeeeeeeeeeee", localStorage.getItem("token")) 
            setLoading(false);
            setMessage(response.data.message);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              history.push({
                pathname: "/super-admin/blog",
              });
              window.location.reload()
            }, []);
            
          } else {
            setMessage(response.data.message);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              setError(false);
            }, [2000]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <>
      {success ? (
        <div className="toaster">
          <div className={"alert"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div className="toaster">
          <div className={"alert-error"}>
            <div>
              {" "}
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.container}>
        <div className={styles.outer}>
          <div className={styles.overflow_hidden}>
            <CardBody className="pt-0">
              <h3 className="text-center">
                <img src={logopng} alt="" width="100px" className="auth-logo-dark" />
              </h3>
              <div>
                <p className="text-muted text-center">
                  Login to continue to WhiteCollarBlue.
                </p>
                <div className="mb-3 form_label">
                  <label>
                    Your Email<span style={{color: "red"}}>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={inputData.email}
                    onChange={handleInputChange}
                    autoComplete="off"
                    placeholder="Enter email"
                  />
                  <span className="text-danger">{formErrors.email}</span>
                </div>

                <div className="mb-3 form_label">
                  <label>
                    Your Password<span style={{color: "red"}}>*</span>
                  </label>
                  <input
                    type={passwordVisible ? "text" : "password"}
                    autoComplete="off"
                    value={inputData.password}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Password"
                    name="password"
                  />
                  <div className={styles.pass_icon}>
                    {passwordVisible ? (
                      <AiFillEyeInvisible
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        className={styles.eye_icon}
                      />
                    ) : (
                      <AiFillEye
                        onClick={() => setPasswordVisible(!passwordVisible)}
                        className={styles.eye_icon}
                      />
                    )}
                  </div>

                  <span className="text-danger">{formErrors.password}</span>
                </div>

                <div className={styles.footer}>
                  <div className={styles.login_btn}>
                    <button
                      onClick={updateProduct}
                      className={styles.update_btn}
                      type="button">
                      Log In
                    </button>
                  </div>
                </div>
              </div>
            </CardBody>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="loader-overly">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(Loginform);
