import React, { useEffect, useLayoutEffect } from "react";
import Nabvar from "./Nabvar";
import Sidebars from "./sidebars";
import Loginform from "./login/login";
import { withRouter } from "react-router-dom";


function SuperAdmin({children, history}) {

  const token = localStorage.getItem("token")
  // useEffect(()=>{
  //   const token = localStorage.getItem("token")
  //   if(token !== null){
  //     history.push({
  //       pathname : "/super-admin/blog"
  //     })
  //   } else{
  //     history.push({
  //       pathname : "/super-admin/login"
  //     })
  //   }
  // },[])

  return (
    <>
      {
        token !== null ?
        <div className="row">
        <div className="col-md-3 ">
          <Sidebars />
        </div>
        <div className="col-md-9 ">
          <Nabvar />
          {children}
        </div>
      </div>
      :
      <Loginform/>
      }
      
    </>
  );
}

export default withRouter(SuperAdmin);
