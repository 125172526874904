import React, {useEffect, useState} from "react";
import style from "./blog.module.scss";
import wcbLogo from "../../../assets/logo.png";
import {Link, NavLink} from "react-router-dom";
import {FaFacebookF, FaTwitter, FaLinkedinIn} from "react-icons/fa";
import {AiOutlineRight} from "react-icons/ai";
import {allblog} from "./blogdata";
import {useScrollTrigger} from "@material-ui/core";
import robort from "../../../assets/chatgpt-in-recruitment.jpg";
import {getBlogList} from "../../../utils/api";

// AiOutlineRight
const AllBlog = () => {
  // const API_URL = process.env.REACT_APP_BLOG_IMAGE;
  // const location = window.location.pathname;
  // const loct = location.split("/");
  // const newblog = loct[3] ? loct[3].replaceAll("-", " ") : "";
  // console.log("location..................", newblog);

  const [blogData, setBlogData] = useState([]);
  // const [blogshow, setBlogshow] = useState(false)

  useEffect(() => {
    getAllBlogList();
  }, []);

  const getAllBlogList = () => {
    getBlogList()
      .then((res) => {
        // console.log("all blog list----------------", res.data.result);
        if (res.data.code === 200) {
          setBlogData(res.data.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log("blog data ============", blogData);

  return (
    <>
      <div className={style.main}>
        <div className={style.container}>
          <div className={style.page_route}>
            <NavLink
              exact
              to="/"
              activeClassName="active"
              className={style.link_col}>
              Home
            </NavLink>
            <AiOutlineRight className={style.page_icon} />
            <NavLink
              className={style.link_col}
              to="/advice-insights"
              activeClassName="active"
              style={{whiteSpace: "nowrap"}}>
              Insights
            </NavLink>
            <AiOutlineRight className={style.page_icon} />
            <p>Blog</p>
          </div>

          <div className={style.blog_start}>
            <div className={style.blog_item}>
              {blogData?.length > 0
                ? blogData?.map((item, i) => {
                    return (
                      <div className={style.blox_box}>
                        <Link to={`/insights/${item._id}`}>
                          <h5 className={style.blog_heading}>{item.tittle}</h5>
                        </Link>
                        <div className={style.blog_images}>
                          <img src={item.image} alt="blog" width="40%" />
                          <p className={style.blog_descs}>
                            {item.description}
                          </p>
                         {/* <a href={`/insights/${item.tittle}`} className={style.pera_link}>
                         <p className={style.blog_descs}>
                            {item.description.length > 50
                              ? `${item.description.slice(0, 200)}...`
                              : item.description}
                          </p>
                         </a> */}
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>

            <div className={style.popular}>
              <div className={style.article}>
                <h5 className={style.article_head}>Popular articles</h5>
                <div className={style.jobseeker}>
                  {blogData?.length > 0
                    ? blogData?.slice(0, 10).map((item) => {
                        return (
                          <>
                            <Link to={`/insights/${item._id}`}>
                              {item.tittle}
                            </Link>
                          </>
                        );
                      })
                    : ""}
                </div>
                {/* <div className={style.jobseeker}>
                  <h6>Employer</h6>
                  {allblog?.length > 0
                    ? allblog?.slice(10).map((item) => {
                        return (
                          <>
                            <a href={`/insights/${item.heading.replaceAll(" ", "-")}`}>{item.heading}</a>
                          </>
                        );
                      })
                    : ""}
                </div> */}
                <h6 className={style.view_blog}>View all blogs</h6>
              </div>
            </div>
          </div>

          {/* <div className={style.footer}> */}
          {/* <div className={style.footer_list}>
             <div className={style.list_item}>
             <ul>
                <li>Sitemap </li> |<li>Site search </li> |<li>About us </li> |
                <li>Our values </li> |
              </ul>
              <ul>
                <li>T&C </li> |<li>Accessibility </li> |<li>Privacy policy </li> |
                <li>Disclaimer </li> |
              </ul>
              <ul>
                <li>Investors </li> |<li>Help </li> |<li>Scam alert </li> |<li>Reconciliation </li>
              </ul>
             </div>
             <div className={style.list_item_hide}>
             <ul>
                <li>Sitemap </li> <li>Site search </li> <li>About us </li> 
                <li>Our values </li> 
              </ul>
              <ul>
                <li>T&C </li> <li>Accessibility </li> <li>Privacy policy </li> 
                <li>Disclaimer </li> 
              </ul>
              <ul>
                <li>Investors </li> <li>Help </li> <li>Scam alert </li> <li>Reconciliation </li>
              </ul>
             </div>
            </div> */}
          {/* <p className={style.copyright}>© Copyright Hays plc 2023.</p>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default AllBlog;
