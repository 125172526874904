import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {withRouter} from "react-router-dom";

import {MdClose} from "react-icons/md";

import "./style.scss";
import FWBBody from "./FWBBody";

const FWBModal = ({location}) => {
  const [open, setOpen] = React.useState(false);

  const isPowerOffer = location.pathname;

  let mouseY = 0;

  document.addEventListener("mousemove", function (e) {
    mouseY = e.clientY;
  });

  document.addEventListener("mouseleave", function (e) {
    if (mouseY < 100) {
      if (window.location.pathname !== "/jobseeker/friendswithbenefits") {
        setOpen(true);
      }
    }
  });

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      {isPowerOffer !== "/power-offer" && isPowerOffer !== "/thank-you"  ?  <div className="fwb-modal">
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className="fwb-modal__dialog">
            <MdClose onClick={handleClose} className="modal-close" />
            <FWBBody modal onMove={handleClose} />
          </Dialog>
        </div> : <></>}
    </>
  );
};

export default withRouter(FWBModal);
