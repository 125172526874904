// Sidebar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SuperAdmin.scss'
import LogoMobile from "../../assets/logo.png";


const Sidebars = () => {

  const [activeItem, setActiveItem] = useState(null);

  const location = window.location.pathname

  useEffect(()=>{
   if(location == "/super-admin/blog"){
    setActiveItem(2)
   }else if(location == "/super-admin/newsletter"){
    setActiveItem(1)
   }else if(location == "/super-admin/subscribe"){
    setActiveItem(3)
   }
  },[])

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  return (
    <>
    <div className="sidebar text-white p-3">
      <div className='bg-white text-center py-2 mt-5 main_icon'>
       <Link to="/">
       <img
          src={LogoMobile}
          alt="img"
          className="sidebar_pic"
        />
       </Link>
      </div>

      <ul className='py-4 my-3 mt-4 pb-4 bg-white '>
      <li className={2 === activeItem ? 'active' : ''}
          onClick={() => handleItemClick(2)}>
          <Link to="/super-admin/blog" className='a_link' style={{color: 2 === activeItem ? "white" : ''}}><h5 className='px-4'> Blog </h5></Link>
        </li>
        <li className={1 === activeItem ? 'active' : ''}
          onClick={() => handleItemClick(1)}>
          <Link to="/super-admin/newsletter" className='a_link' style={{color: 1 === activeItem ? "white" : ''}} > <h5 className='px-4'>News Letter</h5></Link>
        </li>
        
        <li className={3 === activeItem ? 'active' : ''}
          onClick={() => handleItemClick(3)}>
          <Link to="/super-admin/subscribe" className='a_link' style={{color: 3 === activeItem ? "white" : ''}}><h5 className='px-4'> Subscribe Email </h5></Link>
        </li>
      </ul>
    </div>
    </>
  );
};

export default Sidebars;
