import React, {useState, useEffect} from "react";
import style from "./blog.module.scss";
import wcbLogo from "../../../assets/logo.png";
import {NavLink} from "react-router-dom";
import {FaFacebookF, FaTwitter, FaLinkedinIn} from "react-icons/fa";
import {AiOutlineRight} from "react-icons/ai";
import {allblog} from "./blogdata";
import {useScrollTrigger} from "@material-ui/core";
import cubelogo from "../../../assets/cube-logo.png";
import Wcfpdf from "../../../assets/WhiteCollarBlue-Company-Newsletter.pdf";
import {getNewsList} from "../../../utils/api";

// AiOutlineRight
const Newsletter = () => {

  // const API_URL = process.env.REACT_APP_NEWS_IMAGE;
  // const location = window.location.pathname;
  // const loct = location
  // const newblog = loct[3] ? loct[3].replaceAll("-", " ") : "";
  // console.log("location..................", loct);

  const [blogData, setBlogData] = useState([]);
  // const [blogshow, setBlogshow] = useState(false)

  useEffect(() => {
    getAllBlogList();
  }, []);

  const getAllBlogList = () => {
    getNewsList()
      .then((res) => {
        if (res.data.code === 200) {
          setBlogData(res.data.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <>
      <div className={style.main}>
        <div className={style.container}>
        

          <div className={style.page_route}>
            <NavLink
              exact
              to="/"
              activeClassName="active"
              className={style.link_col}>
              Home
            </NavLink>
            <AiOutlineRight className={style.page_icon} />
            <NavLink
              className={style.link_col}
              to="/advice-insights"
              activeClassName="active"
              style={{whiteSpace: "nowrap"}}>
              Insights
            </NavLink>
            <AiOutlineRight className={style.page_icon} />
            <p>Newsletter</p>
          </div>

          <div className={style.blog_start}>
            <div className={style.blog_item}>
              {blogData?.length > 0
                ? blogData?.map((item) => {
                    return (
                      <div className={style.blox_box}>
                        <a
                          href={Wcfpdf}
                          download="WhiteCollarBlue Company Newsletter.pdf"
                          className={style.blog_heading}>
                          {item.tittle}
                        </a>
                        <div className={style.newsletter}>
                          <div className={style.news_image}>
                            <img
                              className={style.cube_logo}
                              src={item.image}
                              alt="blog"
                            />
                          </div>
                          <div className={style.description}>
                            <p className={style.news_desc}>
                             {item.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>

            {/* <div className={style.popular}>
              <div className={style.article}>
                <h5 className={style.article_head}>Popular articles</h5> */}
            {/* <div className={style.jobseeker}>
                  <h6>Jobseeker</h6>
                  {allblog?.length > 0
                    ? allblog?.slice(0, 10).map((item) => {
                        return (
                          <>
                            <a
                              href={`/insights/${item.heading.replaceAll(
                                " ",
                                "-"
                              )}`}>
                              {item.heading}
                            </a>
                          </>
                        );
                      })
                    : ""}
                </div> */}
            {/* <div className={style.jobseeker}>
                  <h6>Employer</h6>
                  {allblog?.length > 0
                    ? allblog?.slice(10).map((item) => {
                        return (
                          <>
                            <a href={`/insights/${item.heading.replaceAll(" ", "-")}`}>{item.heading}</a>
                          </>
                        );
                      })
                    : ""}
                </div> */}
            {/* <h6 className={style.view_blog}>View all blogs</h6>
              </div>
            </div> */}
          </div>

          {/* <div className={style.footer}> */}
          {/* <div className={style.footer_list}>
             <div className={style.list_item}>
             <ul>
                <li>Sitemap </li> |<li>Site search </li> |<li>About us </li> |
                <li>Our values </li> |
              </ul>
              <ul>
                <li>T&C </li> |<li>Accessibility </li> |<li>Privacy policy </li> |
                <li>Disclaimer </li> |
              </ul>
              <ul>
                <li>Investors </li> |<li>Help </li> |<li>Scam alert </li> |<li>Reconciliation </li>
              </ul>
             </div>
             <div className={style.list_item_hide}>
             <ul>
                <li>Sitemap </li> <li>Site search </li> <li>About us </li> 
                <li>Our values </li> 
              </ul>
              <ul>
                <li>T&C </li> <li>Accessibility </li> <li>Privacy policy </li> 
                <li>Disclaimer </li> 
              </ul>
              <ul>
                <li>Investors </li> <li>Help </li> <li>Scam alert </li> <li>Reconciliation </li>
              </ul>
             </div>
            </div> */}
          {/* <p className={style.copyright}>© Copyright Hays plc 2023.</p> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default Newsletter;
