import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {getSubscriberList, updateEmailStatus} from "../../../utils/api";
import {AiFillDelete, AiOutlineCloseCircle} from "react-icons/ai";
import styles from "./subscribe.module.scss";

const Subscribe = () => {

  
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setBlog] = useState([]);

  useEffect(() => {
    getAllNewsList();
  }, []);

  const getAllNewsList = () => {
    setLoading(true);
    getSubscriberList()
      .then((res) => {
        if (res.data.code == 200) {
          setBlog(res.data.result);
          setLoading(false);
        } else {
          setMessage(res.data.message);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            setError(false);
          }, [2000]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleStatus = (item, status) => {
    const id = item._id;
    setLoading(true);
    updateEmailStatus({
      email: item.email,
      status: status,
    })
      .then((res) => {
        if (res.data.code == 200) {
          getAllNewsList();
          setLoading(false);
          setMessage(res.data.message);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, [2000]);
        } else {
          setMessage(res.data.message);
          setError(true);
          setLoading(false);
          setTimeout(() => {
            setError(false);
          }, [2000]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      {success ? (
        <div className="toaster">
          <div className={"alert"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div className="toaster">
          <div className={"alert-error"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.outer}>
        <div className={styles.heading}>
          <h3 className={styles.news_head}>Subscribe Email List</h3>
          {loading ? (
            <div className="loader-overly">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              <div className={styles.add_btn}>
                <Link to="/super-admin/add-email">
                  <button type="button" className={styles.add_item}>
                    Add
                  </button>
                </Link>
              </div>
              <div>
                <table className="table mt-5">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Id</th>
                      <th scope="col">Email</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {data?.length > 0
                    ? data?.map((item, i) => {
                        return (
                          <tbody>
                            <tr>
                              <td scope="row">{i + 1}</td>
                              <td>{item._id}</td>
                              <th>{item.email}</th>
                              <td>
                                <AiFillDelete
                                  onClick={() => handleStatus(item, false)}
                                  className={styles.delete_btn}
                                />
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    : ""}
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Subscribe;
