import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {subscription} from "../../../utils/api";
import styles from "./subscribe.module.scss";
import {AiOutlineCloseCircle} from "react-icons/ai";

const AddNewEmail = ({history}) => {

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    console.log({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });

    setFormErrors({
      ...formErrors,
      [e.target.name]: "",
    });
  };

  const updateProduct = async () => {
    let valid = true;
    const newErrors = {...formErrors};

    const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    if (!inputData.email) {
      valid = false;
      newErrors.email = "Please enter your email address";
    } else if (!emailRegex.test(inputData.email)) {
      valid = false;
      newErrors.email = "Please enter a valid email address";
    }

    setFormErrors(newErrors);

    if (valid) {
      setFormErrors("");
      setLoading(true);
      subscription({
        email: inputData.email,
      })
        .then((response) => {
          if (response.data.code == 200) {
            localStorage.setItem("token", response.data.result.jwtToken);
            setLoading(false);
            setMessage(response.data.message);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              history.push({
                pathname: "/super-admin/subscribe",
              });
            }, [500]);
          } else {
            setMessage(response.data.message);
            setError(true);
            setLoading(false);
            setTimeout(() => {
              setError(false);
            }, [2000]);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {success ? (
        <div className="toaster">
          <div className={"alert"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {error ? (
        <div className="toaster">
          <div className={"alert-error"}>
            <div>
              <strong>{message}</strong>
            </div>
            <AiOutlineCloseCircle
              onClick={() => setSuccess(false)}
              className="closebtn"
            />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={styles.profile}>
        <div className={styles.profile_head}>
          <h3 className={styles.heading_pro}>Add New Email</h3>
        </div>
        <div className={styles.from_inputs}>
          <input
            className="form-control"
            type="email"
            name="email"
            value={inputData.email}
            onChange={handleInputChange}
            autoComplete="off"
            placeholder="Enter email"
          />
          <span className="text-danger">{formErrors.email}</span>
          <div className={styles.add_btn}>
            <button
              onClick={updateProduct}
              className={styles.add_item}
              type="button">
              Add
            </button>
          </div>
        </div>
        {loading ? (
          <div className="loader-overly">
            <div className="loader"></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default withRouter(AddNewEmail);
