import React, {useState} from "react";
import logopng from "../../assets/logo_without-tagline.png";
import {FaUserCircle} from "react-icons/fa";
import style from "./navbar.module.scss";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {withRouter, Link } from "react-router-dom";

function Nabvar({history}) {
  const [menu, setMenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear()
    history.push({
      pathname:"/super-admin/login"
    })
  };

  return (
    <>
      <div className={style.navbars}>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block">
          <DropdownToggle
            className="btn header-item "
            id="page-header-user-dropdown"
            tag="button">
            <FaUserCircle className={style.icon} />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/super-admin/profile">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              Profile
            </DropdownItem>
            <div className="dropdown-divider" />
            <button onClick={handleLogout} className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>Logout</span>
            </button>
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
}

export default withRouter(Nabvar);
